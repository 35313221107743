import React from 'react';
import PropTypes from 'prop-types';

import Link, { propTypes as linkPropTypes } from '../../../../01_atoms/Link';
import Button from '../../../../01_atoms/Button';
import DefaultPicture, { propTypes as picturePropTypes } from '../../../../01_atoms/DefaultPicture';
import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';
import gStyles from '../index.module.scss';

const BBCTAPrimary = ({
  imagePicture,
  button = null,
  title = '',
  list = [],
  color = 'pink',
  behaviorSettings = null,
  uuid = null,
}) => {
  const componentName = 'bb-cta-primary';
  // Check if the selected color class exists in the styles object.
  const classes = [
    'bb',
    gStyles['cta'],
    styles[componentName],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];
  if (styles[componentName + '--color-' + color] !== undefined) {
    classes.push(styles[componentName + '--color-' + color]);
  }
  return (
    <div className={classes.join(' ')} data-component={componentName} data-color={color} id={uuid}>
      {imagePicture && <DefaultPicture loading="lazy" {...imagePicture} />}

      <div className="container">
        <div className={`${styles[componentName + '__overlay']}`}>
          {title && <h2 className={`${styles[componentName + '__title']}`}>{title}</h2>}

          {list.length > 0 && (
            <ul className={`${styles[componentName + '__icon-list']}`}>
              {list.map((item) => (
                <li key={item} className={`${styles[componentName + '__icon-list-item']}`}>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          )}

          {button && button.label && button.nextLink && (
            <Link {...button.nextLink}>
              <Button
                className={`${styles[componentName + '--button']}`}
                tag="a"
                href={button.nextLink.url}
                type={button.type}
                withArrow={color === 'pink'}
              >
                {button.label}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

BBCTAPrimary.propTypes = {
  imagePicture: PropTypes.shape(picturePropTypes).isRequired,
  title: PropTypes.string,
  button: PropTypes.shape({
    label: PropTypes.string.isRequired,
    nextLink: PropTypes.shape(linkPropTypes).isRequired,
    type: PropTypes.oneOf(['primary', 'secondary']),
  }),
  list: PropTypes.arrayOf(PropTypes.string),
  /**
   * Green option displays 'Tick' icon for all points.
   * Pink option displays 'Arrow right' icon for all points.
   */
  color: PropTypes.oneOf(['pink', 'green']),
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBCTAPrimary;
